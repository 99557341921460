import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import colorPaletteV2 from '../../../../styles/color-palette-v2';
import pagesV2 from '../../../../constants-v2/pages-v2';

const Root = styled.footer`
  background-color: ${colorPaletteV2.white};
  .footer-inner {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: 959px) {
      padding-top: 0;
      padding-bottom: 0;
      flex-direction: column;
      width: 100%;
    }
    a {
      color: ${colorPaletteV2.black};
    }
    &_block {
      width: 30%;
      @media (max-width: 959px) {
        width: 100%;
      }
      li.list-1st {
        font-size: 24px;
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 32px;
        margin-bottom: 32px;
      }
      li.list-2nd {
        font-size: 16px;
        font-size: 16px;
        margin-left: 24px;
        margin-left: 24px;
        font-weight: 400;
        margin-bottom: 22px;
        margin-bottom: 22px;
      }
      ul.list-2nd {
        margin-top: 26px;
        margin-top: 26px;
      }
      ul.list-1st-sub {
        margin-top: 48px;
        margin-top: 48px;
      }
      li.list-1st-sub {
        font-size: 16px;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 20px;
        margin-bottom: 20px;
      }
    }
    & .company {
      line-height: 1.8;
      font-size: 14px;
      font-size: 14px;
      width: 24%;
      @media (max-width: 959px) {
        flex-direction: column;
        margin-top: 28px;
        margin-top: 28px;
        width: 100%;
      }
      .company-name {
        font-size: 20px;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 24px;
        margin-bottom: 24px;
      }
      .company-name-kana {
        font-size: 12px;
        font-size: 12px;
        font-weight: 400;
      }
      .company-tel {
        margin-top: 16px;
        margin-top: 16px;
      }
    }
  }
  width: 100%;
  padding: 80px 0 100px;
  padding: 80px 0 100px;
  @media (max-width: 959px) {
    padding: 54px 0 80px;
    padding: 54px 0 80px;
  }
`;

const FooterLink = styled(Link)`
  display: inline-block;
`;

const Footer = () => {
  return (
    <Root>
      <div className="footer-inner wrapper-v2">
        <div className="footer-inner_block">
          <ul>
            {/* <li className="list-1st">
              <FooterLink
                alt={pagesV2.products.name}
                to={pagesV2.products.path}
              >
                {pagesV2.products.label.jp}
              </FooterLink>
            </li> */}
            <li className="list-1st">
              <FooterLink alt={pagesV2.index.name} to={pagesV2.index.path}>
                Regnioの考えるDXとは？
              </FooterLink>
              <ul className="list-2nd">
                <li className="list-2nd">
                  <FooterLink
                    alt={pagesV2.DXoutside.name}
                    to={pagesV2.DXoutside.path}
                  >
                    {pagesV2.DXoutside.label.jp}
                  </FooterLink>
                </li>
                <li className="list-2nd">
                  <FooterLink
                    alt={pagesV2.DXinside.name}
                    to={pagesV2.DXinside.path}
                  >
                    {pagesV2.DXinside.label.jp}
                  </FooterLink>
                </li>
              </ul>
            </li>
            <li className="list-1st">
              <FooterLink alt={pagesV2.works.name} to={pagesV2.works.path}>
                {pagesV2.works.label.jp}
              </FooterLink>
            </li>
            <li className="list-1st">
              <FooterLink
                alt={pagesV2.contents.name}
                to={pagesV2.contents.path}
              >
                {pagesV2.contents.label.jp}
              </FooterLink>
            </li>
          </ul>
        </div>
        <div className="footer-inner_block">
          <ul>
            <li className="list-1st">
              <FooterLink alt={pagesV2.company.name} to={pagesV2.company.path}>
                {pagesV2.company.label.jp}
              </FooterLink>
              <ul className="list-2nd">
                <li className="list-2nd">
                  <FooterLink
                    alt={pagesV2.company.name}
                    to={pagesV2.company.path}
                  >
                    {pagesV2.company.label.jp}
                  </FooterLink>
                </li>
                <li className="list-2nd">
                  <FooterLink
                    alt={pagesV2.vision.name}
                    to={pagesV2.vision.path}
                  >
                    {pagesV2.vision.label.jp}
                  </FooterLink>
                </li>
                <li className="list-2nd">
                  <FooterLink
                    alt={pagesV2.importantForUs.name}
                    to={pagesV2.importantForUs.path}
                  >
                    {pagesV2.importantForUs.label.jp}
                  </FooterLink>
                </li>
                <li className="list-2nd">
                  <FooterLink
                    alt={pagesV2.member.name}
                    to={pagesV2.member.path}
                  >
                    {pagesV2.member.label.jp}
                  </FooterLink>
                </li>
              </ul>
            </li>
            <li className="list-1st">
              <FooterLink alt={pagesV2.contact.name} to={pagesV2.contact.path}>
                {pagesV2.contact.label.jp}
              </FooterLink>
            </li>
            {/* <ul className="list-1st-sub">
              <li className="list-1st-sub">
                <FooterLink alt={pagesV2.news.name} to={pagesV2.news.path}>
                  {pagesV2.news.label.jp}
                </FooterLink>
              </li>
            </ul> */}
          </ul>
        </div>
        <div className="footer-inner_block company">
          <div className="company-name">
            株式会社Regnio
            <span className="company-name-kana">（リグニオ）</span>
          </div>
          <div>
            〒810-0023
            <br />
            福岡県福岡市中央区警固2-2-23
            <br />
            ウイングコート警固 601
          </div>
        </div>
      </div>
    </Root>
  );
};

export default Footer;
