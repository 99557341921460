import { Link } from 'gatsby';
import React, { useState } from 'react';
import styled from 'styled-components';
import colorPaletteV2 from '../../../../styles/color-palette-v2';
import pagesV2 from '../../../../constants-v2/pages-v2';
import RegnioLogo from '../../../../images-v2/svg/regnio_logo_color.svg';
import RegnioLogoWhite from '../../../../images-v2/svg/regnio_logo_white_text.svg';

const Root = styled.header`
  display: flex;
  justify-content: center;

  .header-wraper {
    background: #f5f5f5;
    width: 100%;
    margin-top: 0;
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 20px 24px;
    padding: 20px 24px;
    position: fixed;
    margin: 0 auto;
    z-index: 999;

    @media (min-width: 960px) {
      padding: 32px 0px 16px;
      padding: 32px 0px 16px;
    }
  }
`;

const HeaderInner = styled.div`
  display: flex;
  width: 100% !important;
  max-width: 960px;
`;

const Logo = styled(Link)`
  img {
    width: 158px;
    width: 158px;
    height: 44px;
    height: 44px;
    margin-left: 8px;
    margin-left: 8px;
  }
`;

const MenuWrapPC = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 959px) {
    display: none;
  }
`;

const MenuPCset = styled.div`
  position: relative;
  &:hover > .list-2nd-pc-wrap {
    display: flex;
  }
`;

const MenuPC = styled(Link)`
  display: block;
  font-size: 14px;
  font-size: 14px;
  padding: 12px 16px 30px;
  padding: 12px 16px 30px;
  margin-left: 24px;
  margin-left: 24px;
  transition: all 0.3s;
  position: relative;
  z-index: 1;
  &::after {
    position: absolute;
    top: 40px;
    left: 0;
    content: '';
    width: 100%;
    height: 2px;
    background: ${colorPaletteV2.menuSPbg};
    transform: scale(0, 1);
    transform-origin: center top;
    transition: transform 0.3s;
  }
  &:hover::after {
    transform: scale(1, 1);
  }
`;

const MenuPCsub = styled.ul`
  display: none;
  z-index: 10;
  &.list-2nd-pc-wrap:hover {
    display: flex;
    transition: transform 0.3s;
  }
  &.list-2nd-pc-wrap:hover ~ a::after {
    transform: scale(1, 1);
  }
  flex-direction: column;
  position: absolute;
  top: 48px;
  top: 48px;
  left: 24px;
  left: 24px;
  &.dx {
    width: 264px;
    width: 264px;
  }
  &.company {
    width: 216px;
    width: 216px;
  }
`;

const MenuPCsubLink = styled(Link)`
  color: ${colorPaletteV2.white};
  padding: 12px 16px;
  padding: 12px 16px;
  line-height: 1;
  font-size: 14px;
  font-size: 14px;
  background: ${colorPaletteV2.menuSPbg};
  display: block;
  margin-bottom: 1px;
  &:hover {
    background: ${colorPaletteV2.black};
  }
`;

const MenuSP = styled.button`
  width: 48px;
  width: 48px;
  height: 48px;
  height: 48px;
  margin-left: auto;
  border: none;
  background: none;
  @media (min-width: 960px) {
    display: none;
  }
  &.menu-sp {
    .menuSP-txt {
      position: relative;
      top: 4px;
      color: ${colorPaletteV2.white};
    }
    .menu-hamburger-lines span {
      background-color: ${colorPaletteV2.white};
    }
  }
  .menuSP-txt {
    font-size: 10px;
    font-size: 10px;
  }
  .menu-hamburger-lines,
  .menu-hamburger-lines span {
    display: inline-block;
    transition: all 0.4s;
    box-sizing: border-box;
  }
  .menu-hamburger-lines {
    position: relative;
    width: 20px;
    width: 20px;
    height: 20px;
    height: 20px;
    background: none;
    border: none;
    appearance: none;
    cursor: pointer;
  }
  .menu-hamburger-lines span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${colorPaletteV2.black};
    border-radius: 2px;
  }
  .menu-hamburger-lines span:nth-of-type(1) {
    top: 2px;
  }
  .menu-hamburger-lines span:nth-of-type(2) {
    top: 9px;
  }
  .menu-hamburger-lines span:nth-of-type(3) {
    bottom: 2px;
  }
  // アニメーション
  .menu-hamburger-lines.active span:nth-of-type(1) {
    transform: translateY(10px) rotate(-45deg);
    width: 110%;
  }
  .menu-hamburger-lines.active span:nth-of-type(2) {
    opacity: 0;
  }
  .menu-hamburger-lines.active span:nth-of-type(3) {
    transform: translateY(-10px) rotate(45deg);
    top: 22px;
    width: 110%;
  }
`;

const MenuSPContent = styled.div`
  display: ${props => (props.isVisible ? 'none' : 'block')};
  @media (min-width: 960px) {
    display: none;
  }
  opacity: ${props => props.opacity};
  transition: opacity ease 500ms;
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100vh;
  background: ${colorPaletteV2.menuSPbg};
  .menu-sp-header {
    z-index: 9999;
    flex-direction: row;
    display: flex;
    padding: 20px 24px;
  }
`;

const Nav = styled.nav`
  z-index: 1;
  .footer-inner {
    z-index: -1;
    position: absolute;
    top: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: 959px) {
      padding-top: 100px;
      padding-top: 100px;
      padding-bottom: 40px;
      padding-bottom: 40px;
      flex-direction: column;
      width: 100%;
      height: 100vh;
      height: 100vh;
      overflow: scroll;
    }
    a {
      color: ${colorPaletteV2.white};
    }
    &_block {
      width: 30%;
      @media (max-width: 959px) {
        width: 100%;
      }
      li.list-1st {
        font-size: 24px;
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 32px;
        margin-bottom: 32px;
      }
      li.list-2nd {
        font-size: 16px;
        font-size: 16px;
        margin-left: 24px;
        margin-left: 24px;
        font-weight: 400;
        margin-bottom: 22px;
        margin-bottom: 22px;
      }
      ul.list-2nd {
        margin-top: 26px;
        margin-top: 26px;
      }
      ul.list-1st-sub {
        margin-top: 48px;
        margin-top: 48px;
      }
      li.list-1st-sub {
        font-size: 16px;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 20px;
        margin-bottom: 20px;
      }
    }
    & .company {
      line-height: 1.8;
      font-size: 14px;
      font-size: 14px;
      width: 24%;
      flex-direction: column;
      margin-top: 28px;
      margin-top: 28px;
      width: 100%;
      .company-name {
        color: ${colorPaletteV2.white};
        font-size: 20px;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 24px;
        margin-bottom: 24px;
      }
      .company-name-kana {
        color: ${colorPaletteV2.white};
        font-size: 12px;
        font-size: 12px;
        font-weight: 400;
      }
      .company-address {
        color: ${colorPaletteV2.white};
        margin-top: 16px;
      }
      .company-tel {
        color: ${colorPaletteV2.white};
        margin-top: 16px;
        margin-top: 16px;
      }
    }
  }
`;

const MenuSPLink = styled(Link)`
  color: ${colorPaletteV2.white};
`;

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const toggleModal = () => {
    setIsOpen(!isOpen);
    setIsVisible(!isVisible);
  };

  return (
    <Root>
      <div className="header-wraper">
        <HeaderInner>
          <Logo alt={pagesV2.index.name} to={pagesV2.index.path}>
            <img
              src={RegnioLogo}
              alt={pagesV2.index.name}
              href={pagesV2.index.path}
            />
          </Logo>
          <MenuWrapPC>
            <MenuPC
              alt={pagesV2.products.name}
              to={pagesV2.products.path}
              target="_blank"
            >
              {pagesV2.products.label.jp}
            </MenuPC>
            <MenuPCset>
              <MenuPCsub className="dx list-2nd-pc-wrap">
                <li className="list-2nd-pc">
                  <MenuPCsubLink
                    alt={pagesV2.DXoutside.name}
                    to={pagesV2.DXoutside.path}
                  >
                    {pagesV2.DXoutside.label.jp} （事業変革プログラム）
                  </MenuPCsubLink>
                </li>
                <li className="list-2nd-pc">
                  <MenuPCsubLink
                    alt={pagesV2.DXinside.name}
                    to={pagesV2.DXinside.path}
                  >
                    {pagesV2.DXinside.label.jp} （人材育成プログラム）
                  </MenuPCsubLink>
                </li>
              </MenuPCsub>
              <MenuPC
                className="menu-pc"
                alt={pagesV2.index.name}
                to={pagesV2.index.path}
              >
                DX事業
              </MenuPC>
            </MenuPCset>
            <MenuPC alt={pagesV2.works.name} to={pagesV2.works.path}>
              {pagesV2.works.label.jp}
            </MenuPC>
            <MenuPC alt={pagesV2.contents.name} to={pagesV2.contents.path}>
              {pagesV2.contents.label.jp}
            </MenuPC>
            <MenuPCset>
              <MenuPCsub className="company list-2nd-pc-wrap">
                <li className="list-2nd-pc">
                  <MenuPCsubLink
                    alt={pagesV2.company.name}
                    to={pagesV2.company.path}
                  >
                    {pagesV2.company.label.jp}
                  </MenuPCsubLink>
                </li>
                <li className="list-2nd-pc">
                  <MenuPCsubLink
                    alt={pagesV2.vision.name}
                    to={pagesV2.vision.path}
                  >
                    {pagesV2.vision.label.jp}
                  </MenuPCsubLink>
                </li>
                <li className="list-2nd-pc">
                  <MenuPCsubLink
                    alt={pagesV2.importantForUs.name}
                    to={pagesV2.importantForUs.path}
                  >
                    {pagesV2.importantForUs.label.jp}
                  </MenuPCsubLink>
                </li>
              </MenuPCsub>
              <MenuPC
                className="menu-pc"
                alt={pagesV2.company.name}
                to={pagesV2.company.path}
              >
                {pagesV2.company.label.jp}
              </MenuPC>
            </MenuPCset>
            {/* <MenuPC alt={pagesV2.contact.name} to={pagesV2.contact.path}>
              {pagesV2.contact.label.jp}
            </MenuPC> */}
          </MenuWrapPC>
          <MenuSP onClick={toggleModal}>
            <div className="menu-hamburger-lines">
              <span />
              <span />
              <span />
            </div>
            <span className="menuSP-txt">MENU</span>
          </MenuSP>
        </HeaderInner>
      </div>
      {/* SPのメニューここから */}
      <MenuSPContent
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
      >
        <div className="wrapper-v2 menu-sp-header">
          <Logo
            alt={pagesV2.index.name}
            to={pagesV2.index.path}
            onClick={toggleModal}
          >
            <img
              src={RegnioLogoWhite}
              alt={pagesV2.index.name}
              href={pagesV2.index.path}
            />
          </Logo>
          <MenuSP className="menu-sp" onClick={toggleModal}>
            <div className="menu-hamburger-lines active">
              <span />
              <span />
              <span />
            </div>
            <span className="menuSP-txt">CLOSE</span>
          </MenuSP>
        </div>
        <Nav>
          <div className="footer-inner wrapper-v2">
            <div className="footer-inner_block">
              <ul>
                <li className="list-1st">
                  <MenuSPLink
                    alt={pagesV2.products.name}
                    to={pagesV2.products.path}
                    onClick={toggleModal}
                    target="_blank"
                  >
                    {pagesV2.products.label.jp}
                  </MenuSPLink>
                </li>
                <li className="list-1st">
                  <MenuSPLink
                    alt={pagesV2.index.name}
                    to={pagesV2.index.path}
                    onClick={toggleModal}
                  >
                    Regnioの考えるDXとは？
                  </MenuSPLink>
                  <ul className="list-2nd">
                    <li className="list-2nd">
                      <MenuSPLink
                        alt={pagesV2.DXoutside.name}
                        to={pagesV2.DXoutside.path}
                        onClick={toggleModal}
                      >
                        {pagesV2.DXoutside.label.jp}
                      </MenuSPLink>
                    </li>
                    <li className="list-2nd">
                      <MenuSPLink
                        alt={pagesV2.DXinside.name}
                        to={pagesV2.DXinside.path}
                        onClick={toggleModal}
                      >
                        {pagesV2.DXinside.label.jp}
                      </MenuSPLink>
                    </li>
                  </ul>
                </li>
                <li className="list-1st">
                  <MenuSPLink
                    alt={pagesV2.works.name}
                    to={pagesV2.works.path}
                    onClick={toggleModal}
                  >
                    {pagesV2.works.label.jp}
                  </MenuSPLink>
                </li>
                <li className="list-1st">
                  <MenuSPLink
                    alt={pagesV2.contents.name}
                    to={pagesV2.contents.path}
                    onClick={toggleModal}
                  >
                    {pagesV2.contents.label.jp}
                  </MenuSPLink>
                </li>
                <li className="list-1st">
                  <MenuSPLink
                    alt={pagesV2.company.name}
                    to={pagesV2.company.path}
                    onClick={toggleModal}
                  >
                    {pagesV2.company.label.jp}
                  </MenuSPLink>
                  <ul className="list-2nd">
                    <li className="list-2nd">
                      <MenuSPLink
                        alt={pagesV2.company.name}
                        to={pagesV2.company.path}
                        onClick={toggleModal}
                      >
                        {pagesV2.company.label.jp}
                      </MenuSPLink>
                    </li>
                    <li className="list-2nd">
                      <MenuSPLink
                        alt={pagesV2.vision.name}
                        to={pagesV2.vision.path}
                        onClick={toggleModal}
                      >
                        {pagesV2.vision.label.jp}
                      </MenuSPLink>
                    </li>
                    <li className="list-2nd">
                      <MenuSPLink
                        alt={pagesV2.importantForUs.name}
                        to={pagesV2.importantForUs.path}
                        onClick={toggleModal}
                      >
                        {pagesV2.importantForUs.label.jp}
                      </MenuSPLink>
                    </li>
                  </ul>
                </li>
                {/* <li className="list-1st">
                  <MenuSPLink
                    alt={pagesV2.contact.name}
                    to={pagesV2.contact.path}
                    onClick={toggleModal}
                  >
                    {pagesV2.contact.label.jp}
                  </MenuSPLink>
                </li> */}
              </ul>
            </div>
            <div className="footer-inner_block company">
              <div className="company-name">
                株式会社Regnio
                <span className="company-name-kana">（リグニオ）</span>
              </div>
              <div className="company-address">
                〒810-0023
                <br />
                福岡県福岡市中央区警固2-2-23
                <br />
                ウイングコート警固 601
              </div>
            </div>
          </div>
        </Nav>
      </MenuSPContent>
    </Root>
  );
};

export default Header;
