import { useCallback } from 'react';
import useJudgeServerSideRendering from './judge-ssr';

const useGetOffsets = () => {
  const judgeServerSideRendering = useJudgeServerSideRendering();
  if (judgeServerSideRendering()) {
    return () => {
      return null;
    };
  }
  const getOffsets = useCallback(() => {
    const { pageXOffset } = window;
    const { pageYOffset } = window;
    const offsets = { x: pageXOffset, y: pageYOffset };
    return offsets;
  }, []);

  return getOffsets;
};

export default useGetOffsets;
