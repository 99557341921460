const colorPaletteV2 = {
  white: '#ffffff',
  whitePale: '#f1f1f1',
  surface: '#F5F5F5',
  blue: '#066F9D',
  orange: '#D76A1C',
  black: '#222222',
  blackBlue: '#202026',
  gray: '#686868',
  grayPale: '#C4C4C4',
  menuSPbg: '#414146',

  grad1: 'linear-gradient(90deg, #2c91ee 0%, #f2b62c 100%)',
  grad2: 'linear-gradient(90deg, #6CA5DB 0%, #DEAC38 100%)',
};

export default colorPaletteV2;
