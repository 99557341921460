import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import colorPaletteV2 from '../../../../styles/color-palette-v2';
import '../../../../styles/foundation-v2.css';
import useGetOffsets from '../../../../utils/get-offsets';
import useJudgeServerSideRendering from '../../../../utils/judge-ssr';
// import FloatingContactButtonV2 from './floating-contact-button-v2';
import FooterV2 from './footer-v2';
import HeaderV2 from './header-v2';
import SEOHeaderV2 from './seo-v2';

const Root = styled.div`
  display: inline-block;
  width: 100vw;
  color: ${colorPaletteV2.black};
  background: ${colorPaletteV2.surface};
`;

const V2PageRoot = ({
  children,
  showFooterBorder,
  heroYOffset,
  isLogoAlwaysVisible,
  // displayContactButton,
  pageTitle,
  pageDescription,
  pageUrl,
  ogpImageName,
  ogpImageUrl,
}) => {
  const getOffsets = useGetOffsets();
  const judgeServerSideRendering = useJudgeServerSideRendering();
  const offsets = useRef({ x: 0, y: 0 });

  const innerHeight = judgeServerSideRendering() ? 0 : window.innerHeight;

  const [scrolling, updateScrolling] = useState({ up: false, down: false });
  const [slideInHeader, setSlideInHeader] = useState(true);
  const [slideInFloatingButton, setSlideInFloatingButton] = useState(false);

  const [stopUpdateScrolling, setStopUpdateScrolling] = useState(false);

  const constantlyGetOffsets = !stopUpdateScrolling
    ? setInterval(() => {
        const newOffsets = getOffsets();
        if (newOffsets !== null) {
          if (newOffsets.y < 50) {
            clearInterval(constantlyGetOffsets);
            updateScrolling({ up: true, down: false });
          } else if (newOffsets.y > offsets.current.y) {
            clearInterval(constantlyGetOffsets);
            updateScrolling({ up: false, down: true });
          } else if (newOffsets.y < offsets.current.y) {
            clearInterval(constantlyGetOffsets);
            updateScrolling({ up: true, down: false });
          } else {
            clearInterval(constantlyGetOffsets);
            updateScrolling({ up: false, down: false });
          }
          offsets.current = newOffsets;
        }
      }, 100)
    : setInterval(() => {}, 100);

  useEffect(() => {
    if (scrolling.down && !scrolling.up) {
      clearInterval(constantlyGetOffsets);
      setSlideInHeader(false);
      if (offsets.current.y >= innerHeight && !slideInFloatingButton) {
        setSlideInFloatingButton(true);
      }
    } else if (!scrolling.down && scrolling.up) {
      clearInterval(constantlyGetOffsets);
      setSlideInHeader(true);
      if (offsets.current.y < innerHeight && slideInFloatingButton) {
        setSlideInFloatingButton(false);
      }
    }
  }, [scrolling]);

  return (
    <Root>
      <SEOHeaderV2
        pageTitle={pageTitle}
        pageDescription={pageDescription}
        pageUrl={pageUrl}
        ogpImageName={ogpImageName}
        ogpImageUrl={ogpImageUrl}
      />
      <HeaderV2
        slideIn={slideInHeader}
        innerHeight={innerHeight}
        offsets={offsets.current}
        transparentYOffset={heroYOffset}
        isLogoAlwaysVisible={isLogoAlwaysVisible}
        setStopUpdateScrolling={setStopUpdateScrolling}
        currentUrl={pageUrl}
      />
      {!stopUpdateScrolling ? children : ''}
      <FooterV2 showBorderTop={showFooterBorder} />
      {/* {displayContactButton ? (
        <FloatingContactButtonV2 slideIn={slideInFloatingButton} />
      ) : null} */}
    </Root>
  );
};

export default V2PageRoot;
